<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">
                {{ $t("RESELLER_PAYMENTS.EDIT_RESELLER_PAYMENT") }}
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <resellerPayment-form
              :loading="loading"
              :resellerPaymentData="resellerPayment"
              :formErrors="formErrors"
              @resellerPaymentSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import ResellerPaymentForm from "./partials/ResellerPaymentForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultResellerPayment from "./defaultResellerPayment";

export default {
  layout: "DashboardLayout",

  components: { ResellerPaymentForm },

  mixins: [alertLeave],

  data() {
    return {
      resellerPayment: cloneDeep(defaultResellerPayment),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("resellerPayments/get", id);
        this.resellerPayment =
          this.$store.getters["resellerPayments/resellerPayment"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List ResellerPayments" });
    },

    viewResellerPayment() {
      this.alertLeave = false;
      this.$router.push({
        name: "View ResellerPayment",
        params: { id: this.resellerPayment.id },
      });
    },

    async handleSubmit(resellerPayment) {
      this.loading = true;

      const resellerPaymentData = cloneDeep(resellerPayment);
      if (!resellerPaymentData.reseller.id) {
        delete resellerPaymentData.reseller;
      }

      try {
        await this.$store.dispatch(
          "resellerPayments/update",
          resellerPaymentData
        );
        this.$notify({
          type: "success",
          message: this.$t("RESELLER_PAYMENTS.RESELLER_PAYMENTS_UPDATED"),
        });
        this.viewResellerPayment();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
